import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    const { _settings, _isIzum } = useSelector((state) => {
        return {
            _settings: state.app.settings,
            _isIzum: state.app.isIzum
        };
    });
    const nowYear = new Date(_settings.ts).getFullYear();
    const phone = `+${_settings.phone?.replace(/[^\d]/g, '')}`;
    let banner = (
        <>
            <a
                href="https://mintconf.ru"
                title="MINT BY CENTER"
                className="banner"
            ></a>
            <p>
                &copy; 2021 – {nowYear} ООО “Центр”
                <br />
                {t('footer.copy')}
                <br />
                <a href="https://mintconf.ru/policy" title={t('footer.policy')}>
                    {t('footer.policy')}
                </a>
            </p>
        </>
    );

    let contacts = (
        <>
            <a href={`tel:${phone}`}>{_settings.phone}</a>
            <br />
            <a href={`mailto:${_settings.email}`}>{_settings.email}</a>
        </>
    );

    if (_isIzum) {
        banner = (
            <div className="banner-izum">
                <a
                    href="https://cit.tatarstan.ru"
                    className="banner-izum-logo"
                ></a>
                <a href="https://cit.tatarstan.ru">
                    {t('footer.citrt.banner.str1')}
                    <br />
                    {t('footer.citrt.banner.str2')}
                    <br />
                    {t('footer.citrt.banner.str3')}
                </a>
            </div>
        );

        contacts = (
            <>
                {`${t('footer.citrt.phone')}: `}
                <a href={`tel:${phone}`}>{_settings.phone}</a>
                <br />
                {`${t('footer.citrt.email')}: `}
                <a href={`mailto:${_settings.email}`}>{_settings.email}</a>
            </>
        );
    }

    return (
        <div className={`footer ${_isIzum ? 'izum' : ''}`}>
            <div className="cell">{banner}</div>
            <div className="cell contacts">{contacts}</div>
            <div className="cell">
                <a
                    href={_settings.iosApp}
                    target="_blank"
                    className="app appstore"
                    rel="noreferrer"
                ></a>
                <a
                    href={_settings.androidApp}
                    target="_blank"
                    className="app googleplay"
                    rel="noreferrer"
                ></a>
            </div>
        </div>
    );
}

export default Footer;
