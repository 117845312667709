import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardColorPicker } from '../../../actions';
import { useState } from 'react';
import AbstractColorPicker from './AbstractColorPicker';

function InfoColorPicker({ label, isActive, onClickActivePicker, onOpen }) {
    const { _prejoinInfoBgColor } = useSelector((state) => {
        return {
            _prejoinInfoBgColor: state.users.paletteSettings.prejoinInfoBgColor,
            _prejoinInfoFontColor:
                state.users.paletteSettings.prejoinInfoFontColor
        };
    });
    const dispatch = useDispatch();
    const [background, setBackground] = useState({
        color: `${_prejoinInfoBgColor ? _prejoinInfoBgColor : 'rgba(245,245,245,1)'}`
    });

    useEffect(() => {
        if (_prejoinInfoBgColor) {
            setBackground({ ...background, color: _prejoinInfoBgColor });
        }
    }, [_prejoinInfoBgColor]);
    const handleClickBackground = () => {
        dispatch(dashboardColorPicker(background.color));
    };

    const handleChange = (color) => {
        setBackground({
            ...background,
            color: `rgba(${Object.values(color.rgb).join()})`
        });
        dispatch(
            dashboardColorPicker(`rgba(${Object.values(color.rgb).join()})`)
        );
    };

    return (
        <AbstractColorPicker
            handleClickPicker={handleClickBackground}
            handleChangePicker={handleChange}
            colorStyle={background}
            label={label}
            isActive={isActive}
            onClickActivePicker={onClickActivePicker}
            onOpen={onOpen}
        />
    );
}
export default InfoColorPicker;
