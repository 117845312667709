import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logOut } from '../../app/actions';
import { menuChanged } from '../actions';
import pages from '../content/pages';
import { Box, Button, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { AccountCircle, ExitToApp } from '@mui/icons-material';
import { useState } from 'react';

function AccountButton() {
    const { t } = useTranslation();
    const [accountAnchor, setAccountAnchor] = useState(null);
    const { _user } = useSelector((state) => {
        return {
            _user: state.app.user
        };
    });
    const handlerProfile = (value) => {
        setAccountAnchor(value);
    };
    const dispatch = useDispatch();
    return (
        <Box>
            <Button
                className="profile"
                sx={{
                    mr: '0.25rem',
                    '>span': {
                        mr: '0.25rem'
                    }
                }}
                onClick={() =>
                    dispatch(menuChanged({ selectedMenu: pages.PROFILE }))
                }
                startIcon={<AccountCircle />}
            >
                <Box>{_user.name}</Box>
            </Button>

            <Button
                className="profile"
                onClick={(event) => {
                    handlerProfile(event.currentTarget);
                }}
            >
                <ExitToApp />
            </Button>
            <Menu
                id="menuAccount"
                anchorEl={accountAnchor}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={Boolean(accountAnchor)}
                onClose={() => {
                    setAccountAnchor(null);
                }}
            >
                <MenuItem onClick={() => dispatch(logOut())}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    {t('navBar.logout')}
                </MenuItem>
            </Menu>
        </Box>
    );
}
export default AccountButton;
