import { appFetch } from '../../../app/functions';
import { copyToClipboard } from '../../../app/functions/copyToClipboard';
import { closeConfirmDialog, showNotification } from '../../actions';
import { mapDaysOfWeek } from '../meets/functions';
import {
    FILE_MANAGER_PAGE_CHANGED,
    FILE_MANAGER_DELETE_FILE,
    FILE_MANAGER_DOWNLOAD_FILE,
    FILE_MANAGER_FILTER_DATE_RANGE_CHANGED,
    FILE_MANAGER_LOAD,
    FILE_MANAGER_ORG_CHANGED,
    FILE_MANAGER_SORT_CHANGED,
    FILE_MANAGER_SELECT_ALL_FILES_CHECKED
} from './actionTypes';
import {
    fileManagerLoad,
    fileManagerLoaded,
    fileManagerSelectFilesIds,
    fileManagerPageChanged,
    fileManagerSelectAllFilesChecked
} from './actions';

export const fileManagerMiddlware = (store) => (next) => (action) => {
    const { getState, dispatch } = store;
    const state = getState();

    switch (action.type) {
        case FILE_MANAGER_FILTER_DATE_RANGE_CHANGED: {
            const [startDate, endDate] = action.dates;
            action.startDate = startDate ? Date.parse(startDate) : 0;
            action.endDate = endDate
                ? new Date(endDate).setDate(new Date(endDate).getDate() + 1) -
                  1000
                : 0;
            action.days = [];
            if (startDate && endDate) {
                for (
                    let date = action.startDate;
                    date < action.endDate;
                    date += 60 * 60 * 24 * 1000
                ) {
                    action.days.push(mapDaysOfWeek(new Date(date).getDay()));
                    if (action.days.length >= 7) break;
                }
            }
            dispatch(fileManagerPageChanged(0));
            break;
        }
        case FILE_MANAGER_ORG_CHANGED:
        case FILE_MANAGER_SORT_CHANGED: {
            dispatch(fileManagerPageChanged(0));
            break;
        }

        case FILE_MANAGER_LOAD: {
            const { fileType, pagination, dateRange, orgId, sort } =
                state.fileManager;
            const body = {};
            if (typeof fileType !== 'undefined') {
                body.fileType = fileType;
            }
            if (
                typeof pagination !== 'undefined' &&
                typeof pagination.currentPage !== 'undefined' &&
                typeof pagination.rowsOnPage !== 'undefined'
            ) {
                body.pagination = pagination;
            }
            if (
                typeof dateRange !== 'undefined' &&
                typeof dateRange.startDate === 'number' &&
                typeof dateRange.endDate === 'number' &&
                dateRange.startDate > 0 &&
                dateRange.endDate > 0
            ) {
                body.dateRange = dateRange;
            }
            if (typeof orgId !== 'undefined') {
                body.orgId = orgId;
            }
            if (typeof action.payload !== 'undefined') {
                body.meetId = action.payload;
            }
            if (typeof sort !== 'undefined') {
                body.sort = sort;
            }

            appFetch(
                state.app.backEnd + '/api/files',
                {
                    credentials: 'include',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body)
                },
                (data) => {
                    if (data.status === 'ok') {
                        dispatch(fileManagerLoaded(data.result));
                    } else {
                        dispatch(
                            showNotification({
                                severity: 'error',
                                text: 'fileManager.notifications.error'
                            })
                        );
                    }
                },
                dispatch,
                () => {
                    dispatch(
                        showNotification({
                            severity: 'error',
                            text: 'fileManager.notifications.error'
                        })
                    );
                }
            );
            break;
        }
        case FILE_MANAGER_DELETE_FILE: {
            const filesIds = action.payload.fileIds;
            const body = { filesIds };
            appFetch(
                state.app.backEnd + '/api/files/' + state.fileManager.fileType,
                {
                    credentials: 'include',
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                },
                (data) => {
                    if (data.status === 'ok') {
                        dispatch(closeConfirmDialog());
                        const {
                            pagination,
                            filesCountTotal,
                            filesCount,
                            selectAllFiles,
                            selectFilesIds,
                            meetId
                        } = state.fileManager;
                        const { currentPage, rowsOnPage } = pagination;
                        if (currentPage > 0) {
                            const isLastPage =
                                filesCountTotal - currentPage * rowsOnPage <=
                                filesCount;
                            if (isLastPage && selectAllFiles) {
                                dispatch(
                                    fileManagerPageChanged(currentPage - 1)
                                );
                            }
                        }
                        dispatch(
                            fileManagerSelectFilesIds(
                                selectFilesIds.filter(
                                    (id) => !filesIds.includes(id)
                                )
                            )
                        );
                        dispatch(
                            showNotification({
                                severity: 'success',
                                text: 'fileManager.notifications.deleteFileSucceeded'
                            })
                        );
                        dispatch(fileManagerLoad(meetId));
                    } else {
                        dispatch(
                            showNotification({
                                severity: 'error',
                                text: 'fileManager.notifications.errorDelete'
                            })
                        );
                    }
                },
                dispatch,
                () => {
                    dispatch(
                        showNotification({
                            severity: 'error',
                            text: 'fileManager.notifications.errorDelete'
                        })
                    );
                }
            );
            return;
        }

        case FILE_MANAGER_DOWNLOAD_FILE: {
            const errorText = action.payload.copyToClipboard
                ? 'copyLinkFailed'
                : 'downloadFailed';
            appFetch(
                state.app.backEnd +
                    '/api/files/' +
                    action.payload.fileType +
                    '/' +
                    action.payload.fileId,
                {
                    credentials: 'include',
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                },
                (data) => {
                    if (data.status === 'ok' && data.link) {
                        if (action.payload.copyToClipboard) {
                            copyToClipboard(`${state.app.urlBase}${data.link}`)
                                .then(() => {
                                    dispatch(
                                        showNotification({
                                            severity: 'success',
                                            text: 'fileManager.notifications.copyLinkSucceeded'
                                        })
                                    );
                                })
                                .catch((error) => {
                                    console.error(error);
                                    dispatch(
                                        showNotification({
                                            severity: 'error',
                                            text: `fileManager.notifications.${errorText}`
                                        })
                                    );
                                });
                        } else {
                            window.open(data.link);
                        }
                    } else
                        dispatch(
                            showNotification({
                                severity: 'error',
                                text: `fileManager.notifications.${errorText}`
                            })
                        );
                },
                dispatch,
                () => {
                    dispatch(
                        showNotification({
                            severity: 'error',
                            text: `fileManager.notifications.${errorText}`
                        })
                    );
                }
            );

            break;
        }
        case FILE_MANAGER_SELECT_ALL_FILES_CHECKED: {
            const checkedFilesIds = [];
            action.payload && state.fileManager.files.length > 0
                ? state.fileManager.files.forEach((file) => {
                      checkedFilesIds.push(file._id);
                  })
                : [];
            dispatch(fileManagerSelectFilesIds(checkedFilesIds));
            break;
        }

        case FILE_MANAGER_PAGE_CHANGED: {
            dispatch(fileManagerSelectAllFilesChecked(false));
            break;
        }
    }

    next(action);
};
