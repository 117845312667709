import { Box, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { VisibilityOff, Visibility } from '@mui/icons-material';
import TestLoader from "./TestLoader";
import { TEST_STATUSES } from "../const";
import { addCertificate, editConnectorState, noCheckCert, selectProto, testConnection, usersFormFieldChangedLDAP } from "./actions";
import { showModal } from "../../../actions";
import { splitUserPrincipalName } from "./functions";


function Form({ connector, addNewConnector }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleChange = (event) => {
        dispatch(selectProto(event.target.value));
        if (event.target.value === 'ldap') {
            dispatch(addCertificate(''));
            dispatch(noCheckCert(false));
        }
    };
    const handleChangeCheckCert = () => {
        dispatch(noCheckCert(!_noCheckCert));
    }
    const prevState = useRef();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showErrorMessageDomain, setShowErrorMessageDomain] = useState(false);
    const [showErrorMessageBaseDN, setShowErrorMessageBaseDN] = useState(false);
    const [showErrorMessageDomainName, setShowErrorMessageDomainName] = useState(false);
    const [formValue, setFormValue] = useState('');
    const [baseDNValue, setBaseDNValue] = useState('');
    const [domainValue, setDomainValue] = useState('');
    const [value, setValue] = useState('')
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [disabledInput, setDisabledInput] = useState(false);
    const [userInfo, setUserInfo] = useState(
        {
            hidden: true
        }
    );

    function toggleShow() {
        setUserInfo({ ...userInfo, hidden: !userInfo.hidden })
    }
    const { hidden } = userInfo;
    const helperText = !showErrorMessageDomainName ? t('settings.ldap.form.domainError') : t('c.form.domainUse');
    const { _useLdap, _form, _connectors, _testStatus, _LDAPAutoDomainSettings, _noCheckCert, _cert } = useSelector(state => {
        return {
            _useLdap: state.settings.form.proto,
            _ldap: state.settings.form.proto,
            _form: state.settings.form,
            _connectors: state.settings.connectors,
            _testStatus: state.settings.testStatus,
            _LDAPAutoDomainSettings: state.app.settings.LDAPAutoDomainSettings,
            _noCheckCert: state.settings.form.noCheckCert,
            _cert: state.settings.form.cert
        }
    })
    useEffect(() => {
        if (connector) {
            dispatch(editConnectorState(connector))
        }
    }, [connector])

    useEffect(() => {
        if (_LDAPAutoDomainSettings) {
            if (_form.proto !== 'ldaps') {
                if (_form.name !== ''
                    && _form.proto !== ''
                    && _form.hosts !== ''
                    && _form.userPrincipalName !== ''
                    && _form.password !== ''
                    && !showErrorMessage) {
                    dispatch(
                        showModal({
                            disabledBtn: false
                        })
                    )
                } else {
                    dispatch(
                        showModal({
                            disabledBtn: true
                        })
                    )
                }
            } else {
                if (_form.name !== ''
                    && _form.proto !== ''
                    && _form.hosts !== ''
                    && _form.userPrincipalName !== ''
                    && _form.password !== ''
                    && !showErrorMessage && _cert.trim() !== '') {
                    dispatch(
                        showModal({
                            disabledBtn: false
                        })
                    )
                } else {
                    dispatch(
                        showModal({
                            disabledBtn: true
                        })
                    )
                }
            }
        } else {
            if (_form.proto !== 'ldaps') {
                if (_form.baseDN !== ''
                    && _form.domain !== ''
                    && _form.name !== ''
                    && _form.proto !== ''
                    && _form.hosts !== ''
                    && _form.userPrincipalName !== ''
                    && _form.password !== ''
                    && !showErrorMessage
                    && !showErrorMessageDomain
                    && !showErrorMessageBaseDN) {
                    dispatch(
                        showModal({
                            disabledBtn: false
                        })
                    )
                } else {
                    dispatch(
                        showModal({
                            disabledBtn: true
                        })
                    )
                }
            } else {
                if (_form.baseDN !== ''
                    && _form.domain !== ''
                    && _form.name !== ''
                    && _form.proto !== ''
                    && _form.hosts !== ''
                    && _form.userPrincipalName !== ''
                    && _form.password !== ''
                    && !showErrorMessage
                    && !showErrorMessageDomain
                    && !showErrorMessageBaseDN && _cert.trim() !== '') {
                    dispatch(
                        showModal({
                            disabledBtn: false
                        })
                    )
                } else {
                    dispatch(
                        showModal({
                            disabledBtn: true
                        })
                    )
                }
            }

        }
    }, [_form, _form.proto, showErrorMessage, showErrorMessageDomain, showErrorMessageBaseDN, _LDAPAutoDomainSettings, _noCheckCert, _cert])

    useEffect(() => {
        if (
            _form.proto !== ''
            && _form.hosts !== ''
            && _form.userPrincipalName !== ''
            && _form.password !== ''
            && !showErrorMessage) {
            setDisabledBtn(false);

        } else {
            setDisabledBtn(true);
        }
    }, [_form.proto, _form.hosts, _form.userPrincipalName, _form.password, showErrorMessage])

    useEffect(() => {
        if (_testStatus === TEST_STATUSES.PENDING) {
            setDisabledInput(true);
        } else {
            setDisabledInput(false);
        }
    }, [_testStatus]);
    useEffect(() => {
        if (_testStatus === TEST_STATUSES.PENDING) {
            prevState.current = {
                hosts: _form.hosts,
                userPrincipalName: _form.userPrincipalName,
                password: _form.password
            }
        }
    }, [_testStatus])

    function searchExistingValue(array, value, existing) {
        array.forEach(item => {
            if (existing) {
                if (item.domain.toLowerCase() === value.toLowerCase()) {
                    setShowErrorMessageDomainName(true);
                    dispatch(
                        showModal({
                            disabledBtn: true
                        })
                    )
                    setShowErrorMessage(true);
                    setDisabledBtn(true);
                }
            } else {
                if (item.baseDN.toLowerCase() === value.toLowerCase()) {
                    setShowErrorMessageBaseDN(true);
                    dispatch(
                        showModal({
                            disabledBtn: true
                        })
                    )

                }
                if (item.domain.toLowerCase() === value.toLowerCase()) {
                    setShowErrorMessageDomain(true);
                    dispatch(
                        showModal({
                            disabledBtn: true
                        })
                    )

                }
            }

        })
    }
    useEffect(() => {
        if (formValue !== '') {
            setShowErrorMessageDomainName(false);
            setShowErrorMessage(false);
            if (_LDAPAutoDomainSettings) {
                if (addNewConnector) {
                    searchExistingValue(_connectors, formValue, true)
                } else {
                    const newArr = _connectors.filter(item => item.domain !== connector.domain)
                    searchExistingValue(newArr, formValue, true)
                }
            }

        }
    }, [formValue, value, _LDAPAutoDomainSettings])

    useEffect(() => {
        if (baseDNValue !== '') {
            setShowErrorMessageBaseDN(false);
            if (addNewConnector) {
                searchExistingValue(_connectors, baseDNValue, false)
            } else {
                const newArr = _connectors.filter(item => item.baseDN !== connector.baseDN);
                searchExistingValue(newArr, baseDNValue, false)
            }
        }
    }, [baseDNValue])

    useEffect(() => {
        if (domainValue !== '') {
            setShowErrorMessageDomain(false);
            if (addNewConnector) {
                searchExistingValue(_connectors, domainValue, false)
            } else {
                const newArr = _connectors.filter(item => item.domain !== connector.domain);
                searchExistingValue(newArr, domainValue, false)
            }
        }
    }, [domainValue])

    useEffect(() => {
        if (_testStatus == TEST_STATUSES.SUCCESS || _testStatus === TEST_STATUSES.ERROR) {
            if (_form.hosts !== prevState.current?.hosts || _form.userPrincipalName !== prevState.current.userPrincipalName || _form.password !== prevState.current.password) {
                dispatch(testConnection(TEST_STATUSES.UNKNOWN));
            }
        }
    }, [_form, _testStatus])

    const selectProtocols = [{ name: t('settings.ldap.form.ldap'), value: 'ldap' }, {
        name: t('settings.ldap.form.ldaps'),
        value: 'ldaps'
    }]

    return <>
        <FormControl sx={{ gap: '1.25rem' }}>
            <Box>
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    label={t('settings.ldap.form.name')}
                    name="name"
                    value={_form.name}
                    onChange={event => { dispatch(usersFormFieldChangedLDAP({ field: event.target.name, value: event.target.value })) }}
                />
                <Box sx={{ mt: 0.5 }}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: '0.3fr  0.7fr', gap: 2 }}>
                        <FormControl sx={{ mt: 1, width: '100%' }}>
                            <InputLabel>{t('settings.ldap.form.protocol')}</InputLabel>
                            <Select
                                id="demo-simple-select-helper"
                                value={_useLdap}
                                sx={{ height: '2.5rem' }}
                                onChange={handleChange}
                                input={<OutlinedInput label={t('settings.ldap.form.protocol')} />}
                            >
                                {selectProtocols.map((itemLdap, index) => {
                                    return <MenuItem key={index} value={itemLdap.value}>{itemLdap.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField
                            variant="outlined"
                            size="small"
                            margin="dense"
                            label={t('settings.ldap.form.server')}
                            name="hosts"
                            placeholder={t('settings.ldap.form.ipPort')}
                            value={_form.hosts}
                            disabled={disabledInput}
                            onChange={event => {
                                dispatch(usersFormFieldChangedLDAP({ field: event.target.name, value: event.target.value }))
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: "0.75rem", textAlign: "center" }}>{t('settings.ldap.form.helperTextServer')}</Typography>
                </Box>
                {_useLdap == 'ldaps' ?
                    <Box >
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: "0.85rem", "mb": 1, "mt": 2 }}>{t('settings.ldap.form.titleCert')}</Typography>

                        <TextField
                            id="cert"
                            name="cert"
                            label={t('settings.ldap.form.certificateLabel')}

                            multiline
                            rows={4}
                            value={_form.cert}
                            helperText={_cert.trim() === '' ? t('settings.ldap.form.specifyСertificate') : null}
                            error={_cert.trim() === ''}
                            fullWidth
                            sx={{ mt: 1 }}
                            InputLabelProps={{
                                shrink: true

                            }}
                            onChange={event => {
                                dispatch(addCertificate(event.target.value))
                            }}

                        />

                        <FormControlLabel control={<Switch checked={_noCheckCert} onChange={handleChangeCheckCert} />} label={t('settings.ldap.form.ignoreVerification')} />

                    </Box> : null}
            </Box>
            <Box>
                <Box> {t('settings.ldap.form.labelAd')}</Box>
                <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    label={t('settings.ldap.form.userPrincipalName')}
                    name="userPrincipalName"
                    helperText={showErrorMessage ? helperText : null}
                    error={showErrorMessage}
                    value={_form.userPrincipalName}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    disabled={disabledInput}
                    onChange={event => {
                        const input = event.target.value;

                        dispatch(usersFormFieldChangedLDAP({ field: event.target.name, value: input }))
                        setShowErrorMessage(true);
                        setDisabledBtn(true);

                        if (input.indexOf(' ') === -1 && input.indexOf('@') !== -1) {
                            const { sAMAccountName, domain } = splitUserPrincipalName(input);

                            if (sAMAccountName.length > 0 && domain.length > 0 && !domain.startsWith('.') && !domain.endsWith('.')) {
                                setFormValue(domain);
                                setValue(input)
                                setShowErrorMessage(false);
                                dispatch(showModal({ disabledBtn: false }));
                            } else {
                                setShowErrorMessage(true);
                                dispatch(showModal({ disabledBtn: true }));
                            }
                        } else if (input.length === 0) {
                            setShowErrorMessage(false);
                            dispatch(showModal({ disabledBtn: true }));
                        }
                    }}

                />

                <FormControl
                    sx={{ mb: 1.2 }}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                >
                    <InputLabel
                        htmlFor="hidden-password"
                    >{t('loginPage.password')}</InputLabel>
                    <OutlinedInput
                        id="hidden-password"
                        type={'text'}
                        label={t('loginPage.password')}
                        name="password"
                        inputProps={{
                            autoComplete: 'off'
                        }}
                        value={_form.password}
                        disabled={disabledInput}
                        onChange={event => {
                            dispatch(usersFormFieldChangedLDAP({ field: event.target.name, value: event.target.value.trim() }))
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => toggleShow()}
                                    aria-label="toggle password visibility"
                                    edge="end"
                                >
                                    {hidden ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        {...(hidden ? {
                            sx: { '-webkit-text-security': 'disc' }
                        } : {})}
                    />
                </FormControl>
                <TestLoader disabledBtn={disabledBtn} />
            </Box>

            {!_LDAPAutoDomainSettings ?
                <Box>
                    <Box>{t('settings.ldap.addition')}</Box>
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        label={t('settings.ldap.form.baseDNLabel')}
                        name="baseDN"
                        value={_form.baseDN}
                        disabled={disabledInput}
                        error={showErrorMessageBaseDN}
                        helperText={showErrorMessageBaseDN ? t('settings.ldap.form.baseDNErrow') : null}
                        onChange={event => {
                            const input = event.target.value;
                            dispatch(usersFormFieldChangedLDAP({ field: event.target.name, value: input }))
                            setBaseDNValue(input)
                        }}

                    />
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        label={t('settings.ldap.form.domainLabel')}
                        name="domain"
                        value={_form.domain}
                        disabled={disabledInput}
                        error={showErrorMessageDomain}
                        helperText={showErrorMessageDomain ? t('settings.ldap.form.domainErrorExist') : null}
                        onChange={event => {
                            const input = event.target.value;
                            dispatch(usersFormFieldChangedLDAP({ field: event.target.name, value: event.target.value }))
                            setDomainValue(input);
                        }}

                    />
                </Box>
                : null}



            <Box>
                <Typography sx={{ mb: 1 }}>{t('settings.ldap.form.labelAttrUser')}</Typography>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', columnGap: 2 }}>
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        label={t('settings.ldap.form.userName')}
                        name="displayName"
                        value={_form.displayName}
                        onChange={event => { dispatch(usersFormFieldChangedLDAP({ field: event.target.name, value: event.target.value })) }}
                    />
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        label={t('settings.ldap.form.email')}
                        name="mail"
                        value={_form.mail}
                        onChange={event => {
                            dispatch(usersFormFieldChangedLDAP({ field: event.target.name, value: event.target.value }))
                        }}
                    />
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="dense"
                        fullWidth
                        label={t('settings.ldap.form.phone')}
                        name="mobile"
                        value={_form.mobile}
                        onChange={event => { dispatch(usersFormFieldChangedLDAP({ field: event.target.name, value: event.target.value })) }}
                    />
                </Box>
            </Box>
        </FormControl>

    </>
}
export default Form