import {
    ORGS_LOAD,
    ORGS_LOADED,
    ORGS_PAGE_CHANGED,
    ORGS_PER_PAGE_CHANGED,
    ORGS_FORM_FIELD_CHANGED,
    ORGS_FORM_CLEAR,
    ORGS_FORM_EDIT,
    ORG_ADD,
    ORG_DEL,
    ORG_EDIT,
    USERS_FORM_FIELD_CHANGED,
    USERS_FORM_CLEAR,
    USERS_FORM_EDIT,
    USER_ADD,
    USER_DEL,
    USER_EDIT,
    RESTRICTED_MEETS,
    DASHBOARD_COLOR_PICKER,
    DASHBOARD_IMAGE,
    LOGO_IMG,
    DASHBOARD_COLOR_PICKER_APP,
    LOGO_APPLICATION,
    CUSTOM_THEME,
    CLEAR_CUSTOM_THEME,
    CUSTOM_THEME_ADD,
    OPACITY_BG,
    USERS_FORM_FIELD_CHANGED_PASSWORD,
    CHANGE_PASSWORD_FORM_CLEAR,
    DASHBOARD_COLOR_PICKER_TEXT,
    DASHBOARD_COLOR_PICKER_BG,
    SELECT_COLOR,
    DASHBOARD_IMAGE_APP,
    SELECT_COLOR_APP,
    CLEAR_CUSTOM_THEME_APP,
    CUSTOM_LOAD_NEW_STATE,
    APP_BG_IMAGE_AS_PREJOIN,
    CLEAR_CUSTOM_THEME_ALL,
    USER_ROLES_LIST,
    PASSWORD_CHECK,
    ERROR_MESSAGE,
    LOCAL_ENABLED_CHANGE,
    SERVER_ENABLED_CHANGE,
    SERVER_LIMIT_VALUE,
    SET_RECORDING_FILE_SIZE_ON_SERVER,
    GET_RECORDING_FILE_SIZE_ON_SERVER
} from './actionTypes';

export function orgsLoad() {
    return {
        type: ORGS_LOAD
    };
}

export function orgsLoaded(payload) {
    return {
        type: ORGS_LOADED,
        payload
    };
}

export function orgsPageChanged(payload) {
    return {
        type: ORGS_PAGE_CHANGED,
        payload
    };
}

export function orgsPerPageChanged(payload) {
    return {
        type: ORGS_PER_PAGE_CHANGED,
        payload
    };
}

export function orgsFormFieldChanged(payload) {
    return {
        type: ORGS_FORM_FIELD_CHANGED,
        payload
    };
}
export function localEnabledChange(payload) {
    return {
        type: LOCAL_ENABLED_CHANGE,
        payload
    };
}
export function serverEnabledChange(payload) {
    return {
        type: SERVER_ENABLED_CHANGE,
        payload
    };
}
export function orgsFormClear() {
    return {
        type: ORGS_FORM_CLEAR
    };
}
export function serverLimitValue(payload) {
    return {
        type: SERVER_LIMIT_VALUE,
        payload
    };
}

export function orgsFormEdit(payload) {
    return {
        type: ORGS_FORM_EDIT,
        payload
    };
}

export function customLoadNewState(payload) {
    return {
        type: CUSTOM_LOAD_NEW_STATE,
        payload
    };
}

export function orgAdd() {
    return {
        type: ORG_ADD
    };
}
export function customThemeAdd(payload) {
    return {
        type: CUSTOM_THEME_ADD,
        payload
    };
}

export function orgDel(payload) {
    return {
        type: ORG_DEL,
        payload
    };
}

export function orgEdit(payload) {
    return {
        type: ORG_EDIT,
        payload
    };
}

export function usersFormFieldChanged(payload) {
    return {
        type: USERS_FORM_FIELD_CHANGED,
        payload
    };
}
export function restrictedMeets(payload) {
    return {
        type: RESTRICTED_MEETS,
        payload
    };
}

export function usersFormClear() {
    return {
        type: USERS_FORM_CLEAR
    };
}
export function clearCustomTheme() {
    return {
        type: CLEAR_CUSTOM_THEME
    };
}
export function clearCustomThemeAll() {
    return {
        type: CLEAR_CUSTOM_THEME_ALL
    };
}
export function clearCustomThemeApp() {
    return {
        type: CLEAR_CUSTOM_THEME_APP
    };
}

export function usresFormEdit(payload) {
    return {
        type: USERS_FORM_EDIT,
        payload
    };
}

export function userAdd(payload) {
    return {
        type: USER_ADD,
        payload
    };
}

export function userDel(payload) {
    return {
        type: USER_DEL,
        payload
    };
}

export function userEdit(payload) {
    return {
        type: USER_EDIT,
        payload
    };
}
export function dashboardColorPicker(payload) {
    return {
        type: DASHBOARD_COLOR_PICKER,
        payload
    };
}
export function dashboardColorPickerText(payload) {
    return {
        type: DASHBOARD_COLOR_PICKER_TEXT,
        payload
    };
}
export function dashboardColorPickerBg(payload) {
    return {
        type: DASHBOARD_COLOR_PICKER_BG,
        payload
    };
}
export function dashboardColorPickerApp(payload) {
    return {
        type: DASHBOARD_COLOR_PICKER_APP,
        payload
    };
}
export function dashboardImage(payload) {
    return {
        type: DASHBOARD_IMAGE,
        payload
    };
}
export function dashboardImageApp(payload) {
    return {
        type: DASHBOARD_IMAGE_APP,
        payload
    };
}
export function dashboardLogo(payload) {
    return {
        type: LOGO_IMG,
        payload
    };
}
export function dashboardLogoApplication(payload) {
    return {
        type: LOGO_APPLICATION,
        payload
    };
}
export function customTheme(payload) {
    return {
        type: CUSTOM_THEME,
        payload
    };
}

export function opacityBg(payload) {
    return {
        type: OPACITY_BG,
        payload
    };
}
export function appBgImageAsPrejoin(payload) {
    return {
        type: APP_BG_IMAGE_AS_PREJOIN,
        payload
    };
}
export function selectColor(payload) {
    return {
        type: SELECT_COLOR,
        payload
    };
}
export function selectColorApp(payload) {
    return {
        type: SELECT_COLOR_APP,
        payload
    };
}
export function usersFormFieldChangedPassword(payload) {
    return {
        type: USERS_FORM_FIELD_CHANGED_PASSWORD,
        payload
    };
}
export function changePasswordFormClear() {
    return {
        type: CHANGE_PASSWORD_FORM_CLEAR
    };
}
export function userRolesList(payload) {
    return {
        type: USER_ROLES_LIST,
        payload
    };
}

export function passwordCheck(payload) {
    return {
        type: PASSWORD_CHECK,
        payload
    };
}
export function errorMessage(payload) {
    return {
        type: ERROR_MESSAGE,
        payload
    };
}
export function setRecordingFileSizeOnServer(payload) {
    return {
        type: SET_RECORDING_FILE_SIZE_ON_SERVER,
        payload
    };
}
export function getRecordingFileSizeOnServer(payload) {
    return {
        type: GET_RECORDING_FILE_SIZE_ON_SERVER,
        payload
    };
}
