const FILES_ROWS_ON_PAGE = [10, 25, 50];

const FILES_ORDER_FIELDS = {
    CREATED: 'created',
    SIZE: 'size'
};
const FILES_ORDER_TYPES = {
    ASC: 'asc',
    DESC: 'desc'
};

const FILES_SORT = [
    {
        text: 'fileManager.filter.data_desc',
        value: {
            field: FILES_ORDER_FIELDS.CREATED,
            orderBy: FILES_ORDER_TYPES.DESC
        }
    },
    {
        text: 'fileManager.filter.data_asc',
        value: {
            field: FILES_ORDER_FIELDS.CREATED,
            orderBy: FILES_ORDER_TYPES.ASC
        }
    },
    {
        text: 'fileManager.filter.size_desc',
        value: {
            field: FILES_ORDER_FIELDS.SIZE,
            orderBy: FILES_ORDER_TYPES.DESC
        }
    },
    {
        text: 'fileManager.filter.size_asc',
        value: {
            field: FILES_ORDER_FIELDS.SIZE,
            orderBy: FILES_ORDER_TYPES.ASC
        }
    }
];
export {
    FILES_ORDER_FIELDS,
    FILES_ORDER_TYPES,
    FILES_SORT,
    FILES_ROWS_ON_PAGE
};
