const SELECT_PROTO = 'SELECT_PROTO';
const USERS_FORM_FIELD_CHANGED_LDAP = "USERS_FORM_FIELD_CHANGED_LDAP";
const SETTINGS_FORM_CLEAR = "SETTINGS_FORM_CLEAR";
const CONNECTOR_ADD = "CONNECTOR_ADD";
const CONNECTORS_LOADED = "CONNECTORS_LOADED";
const CONNECTORS_LOAD = "CONNECTORS_LOAD";
const CONNECTOR_DELETE = "CONNECTOR_DELETE"
const CONNECTOR_EDIT = "CONNECTOR_EDIT";
const EDIT_CONNECTOR_STATE = "EDIT_CONNECTOR_STATE";
const CONNECTOR_TEST = "CONNECTOR_TEST";
const TEST_CONNECTION = 'TEST_CONNECTION';
const CONNECTOR_SYNC = "CONNECTOR_SYNC";
const RELOAD_SYNC_CONNECTORS = "RELOAD_SYNC_CONNECTORS";
const NO_CHECK_CERT = "NO_CHECK_CERT"
const ADD_CERTIFICATE = "ADD_CERTIFICATE"
export {
    SELECT_PROTO,
    USERS_FORM_FIELD_CHANGED_LDAP,
    SETTINGS_FORM_CLEAR,
    CONNECTOR_ADD,
    CONNECTORS_LOADED,
    CONNECTORS_LOAD,
    CONNECTOR_DELETE,
    CONNECTOR_EDIT,
    EDIT_CONNECTOR_STATE,
    CONNECTOR_TEST,
    TEST_CONNECTION,
    CONNECTOR_SYNC,
    RELOAD_SYNC_CONNECTORS,
    NO_CHECK_CERT,
    ADD_CERTIFICATE
}