import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropzoneFile from './dropzoneFiles/DropzoneFile';
import { Box, Button, FormControlLabel, Grid, Switch } from '@mui/material';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import PreviewApplication from './previews/previewApplication';
import ColorPickerApplication from './colorPickers/ColorPickerApplication';
import DropzoneFileApp from './dropzoneFiles/DropzoneFileApp';
import { showModalPreview } from '../../../../actions';
import {
    appBgImageAsPrejoin,
    clearCustomThemeApp,
    dashboardColorPickerApp,
    dashboardImageApp,
    selectColorApp
} from '../../actions';
import ResetButton from './settingsComponents/resetButton';
import { useEffect } from 'react';
function SettiingsApplication({ org }) {
    const { _appBgImageAsPrejoin, _prejoinBgImage, _selectColorApp } =
        useSelector((state) => {
            return {
                _appBgImageAsPrejoin:
                    state.users.paletteSettings.appBgImageAsPrejoin,
                _prejoinBgImage: state.users.paletteSettings.prejoinBgImage,
                _selectColorApp: state.users.paletteSettings.selectColorApp
            };
        });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [name, setNameOpened] = useState('');
    const [opened, setOpened] = useState(false);
    const handleChange = () => {
        dispatch(appBgImageAsPrejoin(!_appBgImageAsPrejoin));
    };
    const handleChangeSelect = () => {
        dispatch(selectColorApp(!_selectColorApp));
    };

    useEffect(() => {
        if (_appBgImageAsPrejoin) dispatch(selectColorApp(true));
    }, [_appBgImageAsPrejoin]);
    useEffect(() => {
        if (!_selectColorApp) {
            dispatch(appBgImageAsPrejoin(false));
            dispatch(dashboardImageApp({ image: null }));
        } else {
            dispatch(dashboardColorPickerApp('rgba(60, 58, 66,1)'));
        }
    }, [_selectColorApp]);
    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 58,
        height: 38,
        margin: 0,
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor:
                        theme.palette.mode === 'dark' ? '#1976d2' : '#1976d2'
                }
            }
        },
        '& .MuiSwitch-thumb': {
            backgroundColor:
                theme.palette.mode === 'dark' ? '#1976d2' : '#1976d2'
        },
        '& .MuiSwitch-track': {
            backgroundColor:
                theme.palette.mode === 'dark' ? '#1976d2' : '#1976d2'
        }
    }));
    useEffect(() => {
        if (_appBgImageAsPrejoin) {
            dispatch(dashboardImageApp({ image: null, url: null }));
        }
    }, [_appBgImageAsPrejoin]);
    return (
        <>
            <Grid container columns={16} rowGap={2}>
                <Grid item xs={8}>
                    <Typography variant="h6">
                        {' '}
                        {t('orgs.paletteSettings.titleBg')}
                    </Typography>
                    <FormControlLabel
                        sx={{ mt: 2 }}
                        control={
                            <Switch
                                checked={_appBgImageAsPrejoin}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                                disabled={
                                    _prejoinBgImage.image ||
                                        (_prejoinBgImage.url && _selectColorApp)
                                        ? false
                                        : true
                                }
                            />
                        }
                        label={t('orgs.paletteSettings.useBgLoginPage')}
                    />
                    <Grid sx={{ display: 'flex', alignItems: 'center' }} item>
                        <Grid item>
                            {t('orgs.paletteSettings.selectColor')}
                        </Grid>
                        <FormControlLabel
                            sx={{ m: 0 }}
                            control={<MaterialUISwitch sx={{ m: 1 }} />}
                            checked={_selectColorApp}
                            onChange={handleChangeSelect}
                        />
                        <Grid item>
                            {t('orgs.paletteSettings.selectimage')}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} sx={{ position: 'relative' }}>
                    {_selectColorApp ? (
                        <DropzoneFileApp />
                    ) : (
                        <ColorPickerApplication
                            label={t('orgs.paletteSettings.bgColor')}
                            onClickActivePicker={() => {
                                setNameOpened('applicationBgColor');
                            }}
                            onOpen={() => {
                                setOpened(
                                    name === 'applicationBgColor'
                                        ? !opened
                                        : false
                                );
                            }}
                            isActive={
                                name === 'applicationBgColor' ? !opened : false
                            }
                        />
                    )}
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h6">
                        {' '}
                        {t('orgs.paletteSettings.titleLogo')}
                    </Typography>
                    <Typography
                        sx={{
                            color: '#9e9e9e',
                            fontSize: '0.87rem',
                            width: '80%',
                            mt: 1
                        }}
                    >
                        {' '}
                        {t('orgs.paletteSettings.logoSizeApp')}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <DropzoneFile applicationLogo={true} />
                </Grid>

                <Button
                    variant="contained"
                    sx={{ ml: 'auto' }}
                    onClick={() => {
                        dispatch(
                            showModalPreview({
                                form: <PreviewApplication org={org} />
                            })
                        );
                    }}
                >
                    {t('orgs.paletteSettings.preview')}
                </Button>
            </Grid>
            <Box sx={{ display: 'flex', mt: 2 }}>
                <ResetButton
                    handlerClick={() => {
                        dispatch(clearCustomThemeApp());
                    }}
                />
            </Box>
        </>
    );
}
export default SettiingsApplication;
