import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardColorPickerApp } from '../../../actions';
import AbstractColorPicker from './AbstractColorPicker';
import { useEffect } from 'react';

function ColorPickerApplication({
    label,
    isActive,
    onClickActivePicker,
    onOpen
}) {
    const { _appBgColor } = useSelector((state) => {
        return {
            _appBgColor: state.users.paletteSettings.appBgColor
        };
    });
    const dispatch = useDispatch();
    const [colorApplication, setColorApplication] = useState({
        color: _appBgColor ? _appBgColor : 'rgba(60,58,66,1)'
    });
    useEffect(() => {
        if (_appBgColor) {
            setColorApplication({ ...colorApplication, color: _appBgColor });
        }
    }, [_appBgColor]);

    const handleClick = () => {
        dispatch(dashboardColorPickerApp(colorApplication.color));
    };

    const handleChange = (color) => {
        setColorApplication({
            ...colorApplication,
            color: `rgba(${Object.values(color.rgb).join()})`
        });
        dispatch(
            dashboardColorPickerApp(`rgba(${Object.values(color.rgb).join()})`)
        );
    };
    return (
        <AbstractColorPicker
            handleClickPicker={handleClick}
            handleChangePicker={handleChange}
            colorStyle={colorApplication}
            label={label}
            isActive={isActive}
            onClickActivePicker={onClickActivePicker}
            onOpen={onOpen}
        />
    );
}

export default ColorPickerApplication;
