import {
    FILE_MANAGER_PAGE_CHANGED,
    FILE_MANAGER_CLEAR,
    FILE_MANAGER_FILTER_DATE_RANGE_CHANGED,
    FILE_MANAGER_LOADED,
    FILE_MANAGER_ORG_CHANGED,
    FILE_MANAGER_SELECT_FILES_IDS,
    FILE_MANAGER_SORT_CHANGED,
    FILE_MANAGER_TYPE_CHANGED,
    FILE_MANAGER_ROWS_ON_PAGE_CHANGED,
    FILE_MANAGER_FILTER_RESET
} from './actionTypes';
import { FILES_ROWS_ON_PAGE, FILES_SORT } from './const';
import tabs from './tabs';

export const defaultState = {
    dateRange: {
        startDate: 0,
        endDate: 0
    },
    filesCount: 1,
    filesCountTotal: 1,
    sizeTotal: 0,
    pagination: {
        currentPage: 0,
        rowsOnPage: FILES_ROWS_ON_PAGE[0]
    },
    sizeLimit: 0,
    fileType: tabs.RECORDINGS,
    files: [],
    orgId: undefined,
    sort: FILES_SORT[0].value,
    selectAllFiles: false,
    selectFilesIds: []
};

export function fileManagerReducer(state = defaultState, action) {
    switch (action.type) {
        case FILE_MANAGER_FILTER_DATE_RANGE_CHANGED:
            return {
                ...state,
                dateRange: {
                    startDate: action.startDate,
                    endDate: action.endDate
                }
            };
        case FILE_MANAGER_LOADED:
            return {
                ...state,
                files: action.payload.files,
                filesCount: action.payload.filesCount,
                filesCountTotal: action.payload.filesCountTotal,
                sizeTotal: action.payload.sizeTotal,
                sizeLimit: action.payload.sizeLimit
            };
        case FILE_MANAGER_TYPE_CHANGED: {
            return {
                ...defaultState,
                fileType: action.payload,
                dateRange: state.dateRange,
                orgId: state.orgId,
                sort: state.sort
            };
        }

        case FILE_MANAGER_SORT_CHANGED:
            return {
                ...state,
                sort: action.payload
            };

        case FILE_MANAGER_ORG_CHANGED:
            return {
                ...state,
                orgId:
                    action.payload !== 'not_org'
                        ? action.payload
                        : defaultState.orgId
            };
        case FILE_MANAGER_ROWS_ON_PAGE_CHANGED:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    rowsOnPage: action.payload
                }
            };
        case FILE_MANAGER_PAGE_CHANGED:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload
                }
            };
        case FILE_MANAGER_SELECT_FILES_IDS:
            return {
                ...state,
                selectFilesIds: action.payload,
                selectAllFiles:
                    state.filesCount > 0 &&
                    action.payload.length === state.filesCount
            };

        case FILE_MANAGER_CLEAR:
            return defaultState;
        case FILE_MANAGER_FILTER_RESET:
            return {
                ...defaultState,
                fileType: state.fileType
            };
        default:
            return state;
    }
}
