import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from './actions';
import { withTranslation } from 'react-i18next';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';

function Modal({ t }) {
    const { _modal } = useSelector((state) => {
        return {
            _modal: state.main.modal
        };
    });
    const dispatch = useDispatch();
    return (
        <Dialog
            fullWidth={true}
            maxWidth={_modal.sizeDialog}
            open={_modal.isOpen}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick') {
                    dispatch(closeModal(_modal.closeAction));
                }
            }}
            aria-labelledby="modal-dialog-title"
            aria-describedby="modal-dialog-description"
        >
            <DialogTitle id="modal-dialog-title">{t(_modal.title)}</DialogTitle>
            <DialogContent>{_modal.form}</DialogContent>
            <DialogActions>
                {Boolean(_modal.actionButton) && Boolean(_modal.action) ? (
                    <Button
                        onClick={() => dispatch(_modal.action)}
                        color="primary"
                        disabled={_modal.disabledBtn}
                    >
                        {t(_modal.actionButton)}
                    </Button>
                ) : null}
                <Button
                    onClick={() => dispatch(closeModal(_modal.closeAction))}
                    color="primary"
                    autoFocus
                >
                    {_modal.closeButton
                        ? t(_modal.closeButton)
                        : t('modal.closeButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTranslation()(Modal);
