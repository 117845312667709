import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardColorPickerBg } from '../../../actions';
import { useState } from 'react';
import AbstractColorPicker from './AbstractColorPicker';
import { Box } from '@mui/material';

function BgColorPicker({ label, isActive, onClickActivePicker, onOpen }) {
    const { _prejoinBgColor } = useSelector((state) => {
        return {
            _prejoinBgColor: state.users.paletteSettings.prejoinBgColor
        };
    });

    const dispatch = useDispatch();

    const [background, setBackground] = useState({
        color: _prejoinBgColor !== null ? _prejoinBgColor : 'rgba(54,58,72,1)'
    });

    useEffect(() => {
        if (_prejoinBgColor) {
            setBackground({ ...background, color: _prejoinBgColor });
        }
    }, [_prejoinBgColor]);
    const handleClickBackground = () => {
        dispatch(dashboardColorPickerBg(background.color));
    };

    const handleChange = (color) => {
        setBackground({
            ...background,
            color: `rgba(${Object.values(color.rgb).join()})`
        });
        dispatch(
            dashboardColorPickerBg(`rgba(${Object.values(color.rgb).join()})`)
        );
    };

    return (
        <Box sx={{ height: '13rem' }}>
            <AbstractColorPicker
                handleClickPicker={handleClickBackground}
                handleChangePicker={handleChange}
                colorStyle={background}
                label={label}
                isActive={isActive}
                onClickActivePicker={onClickActivePicker}
                onOpen={onOpen}
            />
        </Box>
    );
}
export default BgColorPicker;
