import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../actions';
import { orgsFormClear, orgAdd } from '../../actions';
import { Box } from '@mui/material';
import Action from '../../../../Action';
import { AddCircleTwoTone } from '@mui/icons-material';
import Form from '../Form';

function Add() {
    const { _multiTenant } = useSelector((state) => {
        return {
            _multiTenant: state.app.settings.multiTenant
        };
    });

    const dispatch = useDispatch();
    if (!_multiTenant) return null;

    return (
        <Box className="orgAdd">
            <Action
                title={'orgs.add.title'}
                icon={<AddCircleTwoTone fontSize="large" />}
                onClickHandle={() => {
                    dispatch(
                        showModal({
                            title: 'orgs.add.title',
                            form: <Form />,
                            actionButton: 'orgs.add.button',
                            action: orgAdd(),
                            closeAction: { action: orgsFormClear() },
                            sizeDialog: 'sm'
                        })
                    );
                }}
            />
        </Box>
    );
}

export default Add;
