import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';

function AbstractColorPicker({
    label,
    handleClickPicker,
    handleChangePicker,
    colorStyle,
    isActive,
    onClickActivePicker,
    onOpen
}) {
    const handleChange = (color) => {
        handleChangePicker(color);
    };
    const { t } = useTranslation();
    const handleClick = () => {
        handleClickPicker();
        onClickActivePicker();
        onOpen();
    };

    const styles = reactCSS({
        default: {
            color: {
                width: '100%',
                height: '100%',
                borderRadius: '2px',
                background: `${colorStyle.color}`
            },
            swatch: {
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                height: '10rem',
                width: '100%',
                maxWidth: '100%'
            },
            positionActivePicker: {
                position: 'absolute',
                bottom: '0',
                right: '0',
                transform: 'translate(-80%,20%)',
                zIndex: '2'
            }
        }
    });

    return (
        <Box sx={{ position: 'relative' }}>
            {label ? <Typography sx={{ mb: 2 }}>{label}</Typography> : null}
            <Grid
                item
                style={styles.swatch}
                xs={8}
                sx={{ position: 'relative' }}
                className="colorPickerContainer"
            >
                <Box
                    fontSize="large"
                    sx={{ position: 'absolute', top: '0', right: '0' }}
                    className="colorizeHover"
                >
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleClick();
                        }}
                    >
                        {isActive
                            ? t('orgs.paletteSettings.close')
                            : t('orgs.paletteSettings.selectColorBtn')}
                    </Button>
                </Box>
                <Box style={styles.color} />
            </Grid>
            <Box className="colorPicker">
                {isActive ? (
                    <Box style={styles.positionActivePicker}>
                        <Box />
                        <SketchPicker
                            color={colorStyle.color}
                            onChange={handleChange}
                        />
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
}

export default AbstractColorPicker;
