import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, InputLabel, FormControl } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { fileManagerFilterDateRangeChanged } from '../actions';
import { useTranslation } from 'react-i18next';

registerLocale('ru', ru);

function FileDateRange() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { dateRange: _dateRange } = useSelector((state) => state.fileManager);
    const defaultValue = {
        text: t('fileManager.filter.selectDate'),
        array: [null, null]
    };
    const [dateRange, setDateRange] = useState(defaultValue.array);
    const [dateRangeText, setDateRangeText] = useState(defaultValue.text);
    const [startDate, endDate] = dateRange;
    const today = new Date();
    useEffect(() => {
        if (startDate) {
            setDateRangeText(`${moment(startDate).format('DD.MM.YYYY')} - `);
        }
        if (endDate) {
            setDateRangeText(
                `${dateRangeText}${moment(endDate).format('DD.MM.YYYY')}`
            );
            dispatch(fileManagerFilterDateRangeChanged(dateRange));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (!_dateRange.startDate && !_dateRange.endDate) {
            setDateRange(defaultValue.array);
            setDateRangeText(defaultValue.text);
        }
    }, [_dateRange]);

    const DatePickerInput = forwardRef(({ onClick }, ref) => (
        <TextField
            variant="outlined"
            size="small"
            margin="dense"
            label={t('fileManager.date')}
            onClick={onClick}
            value={dateRangeText}
            className="dateRange"
        />
    ));
    DatePickerInput.displayName = 'DatePickerInput';
    return (
        <DatePicker
            minDate={false}
            maxDate={today.setDate(today.getDate())}
            selectsRange={true}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={(dates) => {
                if (dates) {
                    setDateRange(dates);
                } else {
                    setDateRange(defaultValue.array);
                    setDateRangeText(defaultValue.text);
                    dispatch(
                        fileManagerFilterDateRangeChanged(defaultValue.array)
                    );
                }
            }}
            isClearable={true}
            shouldCloseOnSelect={false}
            locale="ru"
            customInput={<DatePickerInput ref={React.createRef()} />}
        />
    );
}

export default FileDateRange;
