import {
    TableCell,
    TableContainer,
    TableBody,
    Table,
    TablePagination,
    TableRow
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    fileManagerPageChanged,
    fileManagerRowsOnPageChanged
} from '../actions';
import { FILES_ROWS_ON_PAGE } from '../const';
function Pagination() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pagination, filesCountTotal, files, filesCount } = useSelector(
        (state) => state.fileManager
    );
    const { currentPage, rowsOnPage } = pagination;
    const numberRecords = (to, count) => {
        return files.length !== 0
            ? `${t('fileManager.pagination.visibleRecords')}: ${to}/${count}`
            : `${t('fileManager.pagination.visibleRecords')}: ${count}`;
    };
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell
                            sx={{
                                fontSize: '0.875rem',
                                color: 'rgba(0, 0, 0, 0.87)',
                                width: '100%'
                            }}
                        >
                            {numberRecords(filesCount, filesCountTotal)}
                        </TableCell>
                        <TablePagination
                            sx={{
                                display: 'flex',
                                width: '32rem',
                                justifyContent: 'flex-end',
                                borderBottom: '1px solid rgba(224, 224, 224, 1)'
                            }}
                            labelRowsPerPage={t(
                                'fileManager.pagination.rowsPerPage'
                            )}
                            labelDisplayedRows={() =>
                                `${t('fileManager.pagination.page')} ${currentPage + 1}`
                            }
                            backIconButtonProps={{
                                title: t('fileManager.pagination.backward')
                            }}
                            nextIconButtonProps={{
                                title: t('fileManager.pagination.forward')
                            }}
                            colSpan={6}
                            count={filesCountTotal}
                            page={currentPage}
                            onPageChange={(event, nextPage) => {
                                dispatch(fileManagerPageChanged(nextPage));
                            }}
                            rowsPerPage={rowsOnPage}
                            rowsPerPageOptions={FILES_ROWS_ON_PAGE}
                            onRowsPerPageChange={(event) => {
                                dispatch(
                                    fileManagerRowsOnPageChanged(
                                        parseInt(event.target.value, 10)
                                    )
                                );
                            }}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true
                            }}
                        />
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default Pagination;
