import React, { useEffect, useState } from 'react';
import {
    Paper,
    Tabs,
    Tab,
    Box,
    TableContainer,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Tooltip
} from '@mui/material';
import {
    OndemandVideoTwoTone,
    InsertDriveFileTwoTone,
    ContactPageTwoTone,
    ChatTwoTone,
    SourceTwoTone
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Delete from '@mui/icons-material/Delete';
import tabs from './tabs';
import Filter from './filter/Filter';
import {
    fileManagerDeleteFile,
    fileManagerLoad,
    fileManagerSelectFilesIds,
    fileManagerTypeChanged,
    fileManagerSelectAllFilesChecked,
    fileManagerClear
} from './actions';
import { prettyDateTime } from '../../../PrettyDateTime';
import fileSize from 'filesize';
import Buttons from './Buttons';
import Pagination from './pagination/Pagination';
import Action from '../../Action';
import { showConfirmDialog } from '../../actions';
import { USER_PERMS } from '../../../const';
function FileManager({ meetId = undefined }) {
    const isDialogWindow = typeof meetId !== 'undefined';
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        sizeTotal,
        files,
        fileType,
        dateRange,
        pagination,
        orgId,
        sort,
        selectFilesIds,
        selectAllFiles,
        sizeLimit
    } = useSelector((state) => state.fileManager);
    const [colorLimit, setColorLimit] = useState(0);

    const { orgRecordingLimitEnabled } = useSelector(
        (state) => state.app.settings
    );

    const { perms } = useSelector((state) => state.app.user);

    const { currentPage, rowsOnPage } = pagination;
    const { endDate } = dateRange;
    const styleHeightOverflow = isDialogWindow ? { maxHeight: '23rem' } : null;
    const [sizeOnPage, setSizeOnPage] = useState(0);
    const [sizeOnSelect, setSizeOnSelect] = useState(0);
    useEffect(() => {
        dispatch(fileManagerClear());
    }, []);
    useEffect(() => {
        dispatch(fileManagerLoad(meetId));
    }, [fileType, currentPage, rowsOnPage, endDate, orgId, sort]);

    useEffect(() => {
        let onPage = 0;
        let onSelect = 0;
        files.map((file) => {
            onPage += file.size;
            if (selectFilesIds.includes(file._id)) {
                onSelect += file.size;
            }
        });
        setSizeOnPage(onPage);
        setSizeOnSelect(onSelect);
    }, [selectFilesIds, files]);

    useEffect(() => {
        if (orgRecordingLimitEnabled) {
            if (sizeTotal > 0 && sizeLimit > 0) {
                setColorLimit(Number((sizeTotal / sizeLimit) * 100));
            }
        }
    }, [sizeTotal, sizeLimit, orgRecordingLimitEnabled]);

    const handleChange = (id) => {
        dispatch(
            fileManagerSelectFilesIds(
                !selectFilesIds.includes(id)
                    ? [...selectFilesIds, id]
                    : selectFilesIds.filter((_id) => _id !== id)
            )
        );
    };

    function renderTab() {
        if (files?.length > 0)
            return files.map((file, index) => {
                const { created, meet, name, size, org, _id } = file;
                return (
                    <TableRow key={index}>
                        <TableCell align="right">
                            <Tooltip
                                title={t(`fileManager.filter.check`)}
                                arrow
                            >
                                <Checkbox
                                    checked={selectFilesIds.includes(_id)}
                                    key={_id}
                                    onChange={(event) => {
                                        handleChange(_id, event.target.checked);
                                    }}
                                />
                            </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                            {prettyDateTime(parseInt(created, 10))}
                        </TableCell>
                        {!isDialogWindow && (
                            <TableCell align="center">
                                {meet?.description}
                            </TableCell>
                        )}
                        <TableCell align="center">{name}</TableCell>

                        <TableCell align="center">
                            {fileSize(size, { round: 1, separator: ',' })}
                        </TableCell>
                        {!isDialogWindow &&
                            perms.includes(USER_PERMS.ORGS) &&
                            org && (
                                <TableCell align="center">
                                    {org?.name}
                                </TableCell>
                            )}
                        <TableCell
                            align="right"
                            sx={{
                                width: '9rem',
                                padding: '16px 8px 16px 16px'
                            }}
                        >
                            <Buttons
                                fileId={_id}
                                fileName={name}
                                fileType={fileType}
                            ></Buttons>
                        </TableCell>
                    </TableRow>
                );
            });
        return (
            <>
                <TableRow>
                    <TableCell colSpan={7} align="center">
                        <span>{t('fileManager.empty')}</span>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    const { RECORDINGS, FILES, PARTICIPANTS, CHAT, DOCS } = tabs;
    return (
        <>
            {!isDialogWindow && <Filter />}
            <Paper>
                <Tabs
                    textColor="primary"
                    indicatorColor="primary"
                    variant="standard"
                    value={fileType}
                    onChange={(event, value) => {
                        dispatch(fileManagerTypeChanged(value));
                    }}
                    className="tabsFileManager"
                    sx={
                        isDialogWindow && {
                            '>div>div': {
                                justifyContent: 'center',
                                flexDirection: 'row'
                            },
                            '>div>div>button': {
                                flex: '1 1 0'
                            }
                        }
                    }
                >
                    <Tab
                        label={t(`fileManager.${RECORDINGS}`)}
                        icon={<OndemandVideoTwoTone />}
                        value={RECORDINGS}
                        sx={!isDialogWindow && { minWidth: '12rem' }}
                    />
                    {isDialogWindow && (
                        <Tab
                            label={t(`fileManager.${DOCS}`)}
                            icon={<InsertDriveFileTwoTone />}
                            value={DOCS}
                        />
                    )}
                    {isDialogWindow && (
                        <Tab
                            label={t(`fileManager.${FILES}`)}
                            icon={<SourceTwoTone />}
                            value={FILES}
                        />
                    )}
                    {isDialogWindow && (
                        <Tab
                            label={t(`fileManager.${CHAT}`)}
                            icon={<ChatTwoTone />}
                            value={CHAT}
                        />
                    )}
                    {isDialogWindow && (
                        <Tab
                            label={t(`fileManager.${PARTICIPANTS}`)}
                            icon={<ContactPageTwoTone />}
                            value={PARTICIPANTS}
                        />
                    )}
                </Tabs>
            </Paper>
            <Paper className="archive">
                {!isDialogWindow && files?.length > 0 && (
                    <Box sx={{ padding: '1.5rem', paddingBottom: '0.5rem' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '2rem',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                opacity: 0.7,
                                fontWeight: 600
                            }}
                        >
                            <Typography
                                style={{ fontSize: '0.9rem' }}
                                variant="span"
                            >
                                {t('fileManager.onPageSizeFiles', {
                                    sizeOnSelect: fileSize(sizeOnSelect, {
                                        round: 1,
                                        separator: ','
                                    }),
                                    sizeOnPage: fileSize(sizeOnPage, {
                                        round: 1,
                                        separator: ','
                                    })
                                })}
                            </Typography>
                            {orgRecordingLimitEnabled &&
                                sizeLimit > 0 &&
                                fileType === RECORDINGS ? (
                                <Typography
                                    style={{ fontSize: '0.9rem' }}
                                    variant="span"
                                    sx={{
                                        backgroundColor:
                                            colorLimit <= 75
                                                ? 'rgb(131 255 131)'
                                                : colorLimit <= 90
                                                    ? 'rgb(255 190 102)'
                                                    : 'rgb(251 90 90)',
                                        p: 1,
                                        marginTop: '-0.5rem',
                                        marginRight: '-0.5rem',
                                        borderRadius: '0.25rem'
                                    }}
                                >
                                    {t('fileManager.used', {
                                        sizeTotal: fileSize(sizeTotal, {
                                            round: 1,
                                            separator: ','
                                        })
                                    })}
                                    <span> из </span>
                                    {fileSize(sizeLimit, {
                                        round: 1,
                                        separator: ','
                                    })}
                                </Typography>
                            ) : (
                                <Typography
                                    style={{ fontSize: '0.9rem' }}
                                    variant="span"
                                >
                                    {t('fileManager.onServerSizeFiles', {
                                        sizeTotal: fileSize(sizeTotal, {
                                            round: 1,
                                            separator: ','
                                        })
                                    })}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                )}

                <TableContainer style={styleHeightOverflow}>
                    <Table>
                        {files?.length > 0 && (
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="right"
                                        sx={{ width: '1rem' }}
                                    >
                                        <Tooltip
                                            title={t(
                                                `fileManager.filter.checkedAll`
                                            )}
                                            arrow
                                        >
                                            <Checkbox
                                                checked={selectAllFiles}
                                                key={'delete_all'}
                                                onChange={(event) => {
                                                    dispatch(
                                                        fileManagerSelectAllFilesChecked(
                                                            event.target.checked
                                                        )
                                                    );
                                                }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ width: '8rem' }}
                                    >
                                        {t('fileManager.date')}
                                    </TableCell>
                                    {!isDialogWindow && (
                                        <TableCell align="center">
                                            {t('fileManager.theme')}
                                        </TableCell>
                                    )}

                                    <TableCell align="center">
                                        {t('fileManager.fileName')}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ width: '8rem' }}
                                    >
                                        {t('fileManager.fileSize')}
                                    </TableCell>

                                    {!isDialogWindow &&
                                        perms.includes(USER_PERMS.ORGS) && (
                                            <TableCell align="center">
                                                {t('fileManager.org')}
                                            </TableCell>
                                        )}

                                    <TableCell
                                        align="right"
                                        sx={{
                                            width: '9rem',
                                            padding: '16px 8px 16px 16px'
                                        }}
                                    >
                                        {selectFilesIds.length > 0 ? (
                                            <Action
                                                title={
                                                    'fileManager.filter.deleteChecked'
                                                }
                                                icon={<Delete color="error" />}
                                                onClickHandle={() => {
                                                    dispatch(
                                                        showConfirmDialog({
                                                            title: 'fileManager.deleteTitle',
                                                            text: {
                                                                key: t(
                                                                    'fileManager.filter.deleteTextChecked',
                                                                    {
                                                                        length: selectFilesIds.length
                                                                    }
                                                                )
                                                            },
                                                            actionButton:
                                                                'fileManager.delete',
                                                            action: fileManagerDeleteFile(
                                                                selectFilesIds
                                                            )
                                                        })
                                                    );
                                                }}
                                            />
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        )}

                        <TableBody>{renderTab()}</TableBody>
                    </Table>
                </TableContainer>
                {files?.length > 0 && <Pagination />}
            </Paper>
        </>
    );
}

export default FileManager;
