const ORGS_LOAD = 'ORGS_LOAD';
const ORGS_LOADED = 'ORGS_LOADED';
const ORGS_PAGE_CHANGED = 'ORGS_PAGE_CHANGED';
const ORGS_PER_PAGE_CHANGED = 'ORGS_PER_PAGE_CHANGED';
const ORGS_FORM_FIELD_CHANGED = 'ORGS_FORM_FIELD_CHANGED';
const ORGS_FORM_CLEAR = 'ORGS_FORM_CLEAR';
const ORGS_FORM_EDIT = 'ORGS_FORM_EDIT';
const ORG_ADD = 'ORG_ADD';
const ORG_DEL = 'ORG_DEL';
const ORG_EDIT = 'ORG_EDIT';
const USERS_FORM_FIELD_CHANGED = 'USERS_FORM_FIELD_CHANGED';
const USERS_FORM_CLEAR = 'USERS_FORM_CLEAR';
const CHANGE_PASSWORD_FORM_CLEAR = 'CHANGE_PASSWORD_FORM_CLEAR';
const USERS_FORM_EDIT = 'USERS_FORM_EDIT';
const USER_ADD = 'USER_ADD';
const USER_DEL = 'USER_DEL';
const USER_EDIT = 'USER_EDIT';
const RESTRICTED_MEETS = 'RESTRICTED_MEETS';
const DASHBOARD_COLOR_PICKER = 'DASHBOARD_COLOR_PICKER';
const DASHBOARD_COLOR_PICKER_BG = 'DASHBOARD_COLOR_PICKER_BG';
const DASHBOARD_COLOR_PICKER_TEXT = 'DASHBOARD_COLOR_PICKER_TEXT';
const DASHBOARD_COLOR_PICKER_APP = 'DASHBOARD_COLOR_PICKER_APP';
const DASHBOARD_IMAGE = 'DASHBOARD_IMAGE';
const LOGO_IMG = 'LOGO_IMG';
const LOGO_APPLICATION = 'LOGO_APPLICATION';
const CUSTOM_THEME = 'CUSTOM_THEME';
const CLEAR_CUSTOM_THEME = 'CLEAR_CUSTOM_THEME';
const CLEAR_CUSTOM_THEME_APP = 'CLEAR_CUSTOM_THEME_APP';
const CLEAR_CUSTOM_THEME_ALL = 'CLEAR_CUSTOM_THEME_ALL';
const CUSTOM_THEME_ADD = 'CUSTOM_THEME_ADD';
const USE_BG_LOGIN_PAGE = 'USE_BG_LOGIN_PAGE';
const OPACITY_BG = 'OPACITY_BG';
const USERS_FORM_FIELD_CHANGED_PASSWORD = 'USERS_FORM_FIELD_CHANGED_PASSWORD';
const SELECT_COLOR = 'SELECT_COLOR';
const SELECT_COLOR_APP = 'SELECT_COLOR_APP';
const DASHBOARD_IMAGE_APP = 'DASHBOARD_IMAGE_APP';
const CUSTOM_LOAD_NEW_STATE = 'CUSTOM_LOAD_NEW_STATE';
const APP_BG_IMAGE_AS_PREJOIN = 'APP_BG_IMAGE_AS_PREJOIN';
const USER_ROLES_LIST = 'USER_ROLES_LIST';
const PASSWORD_CHECK = 'PASSWORD_CHECK';
const ERROR_MESSAGE = 'ERROR_MESSAGE';
const LOCAL_ENABLED_CHANGE = 'LOCAL_ENABLED_CHANGE';
const SERVER_ENABLED_CHANGE = 'SERVER_ENABLED_CHANGE';
const SERVER_LIMIT_VALUE = 'SERVER_LIMIT_VALUE';
const SET_RECORDING_FILE_SIZE_ON_SERVER = 'SET_RECORDING_FILE_SIZE_ON_SERVER';
const GET_RECORDING_FILE_SIZE_ON_SERVER = 'GET_RECORDING_FILE_SIZE_ON_SERVER';
export {
    ORGS_LOAD,
    ORGS_LOADED,
    ORGS_PAGE_CHANGED,
    ORGS_PER_PAGE_CHANGED,
    ORGS_FORM_FIELD_CHANGED,
    ORGS_FORM_CLEAR,
    ORGS_FORM_EDIT,
    ORG_ADD,
    ORG_DEL,
    ORG_EDIT,
    USERS_FORM_FIELD_CHANGED,
    USERS_FORM_CLEAR,
    USERS_FORM_EDIT,
    USER_ADD,
    USER_DEL,
    USER_EDIT,
    RESTRICTED_MEETS,
    DASHBOARD_COLOR_PICKER,
    DASHBOARD_COLOR_PICKER_TEXT,
    DASHBOARD_IMAGE,
    LOGO_IMG,
    DASHBOARD_COLOR_PICKER_APP,
    LOGO_APPLICATION,
    CUSTOM_THEME,
    CLEAR_CUSTOM_THEME,
    CUSTOM_THEME_ADD,
    USE_BG_LOGIN_PAGE,
    OPACITY_BG,
    USERS_FORM_FIELD_CHANGED_PASSWORD,
    CHANGE_PASSWORD_FORM_CLEAR,
    DASHBOARD_COLOR_PICKER_BG,
    SELECT_COLOR,
    SELECT_COLOR_APP,
    DASHBOARD_IMAGE_APP,
    CLEAR_CUSTOM_THEME_APP,
    CUSTOM_LOAD_NEW_STATE,
    APP_BG_IMAGE_AS_PREJOIN,
    CLEAR_CUSTOM_THEME_ALL,
    USER_ROLES_LIST,
    PASSWORD_CHECK,
    ERROR_MESSAGE,
    LOCAL_ENABLED_CHANGE,
    SERVER_LIMIT_VALUE,
    SERVER_ENABLED_CHANGE,
    SET_RECORDING_FILE_SIZE_ON_SERVER,
    GET_RECORDING_FILE_SIZE_ON_SERVER
};
