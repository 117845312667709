import React from 'react';
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import OrgsFilter from './OrgsFilter';
import Sorting from './Sorting';
import FileDateRange from './FileDateRange';
import { useDispatch, useSelector } from 'react-redux';
import { USER_PERMS } from '../../../../const';
import { fileManagerFilterReset } from '../actions';
import { defaultState } from '../reducer';
function Filter() {
    const { t } = useTranslation();
    const { perms } = useSelector((state) => state.app.user);
    const dispatch = useDispatch();
    const { dateRange, sort, orgId } = useSelector(
        (state) => state.fileManager
    );
    const isButtonDisabled =
        JSON.stringify(dateRange) === JSON.stringify(defaultState.dateRange) &&
        JSON.stringify(sort) === JSON.stringify(defaultState.sort) &&
        typeof orgId === 'undefined';
    return (
        <Box className="meetsFilter" sx={{ mt: 2, mb: 2 }}>
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="meetsFilter"
                    id="meetsFilter"
                >
                    <Typography>{t('fileManager.title')}</Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <FileDateRange />
                    <Sorting />
                    {perms.includes(USER_PERMS.ORGS) && <OrgsFilter />}
                    <Button
                        disabled={isButtonDisabled}
                        sx={{ mt: '4px', ml: '10px' }}
                        variant="contained"
                        onClick={() => {
                            dispatch(fileManagerFilterReset());
                        }}
                    >
                        {t('fileManager.filter.reset')}
                    </Button>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default Filter;
