import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fileManagerOrgChanged } from '../actions';

function OrgsFilter() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { _orgsMap, _orgId } = useSelector((state) => {
        return {
            _orgsMap: state.app.settings.orgsMap,
            _orgId: state.fileManager.orgId
        };
    });

    function renderOrgsItems() {
        const orgsItems = [
            <MenuItem key="not_org" value="not_org">
                {t('fileManager.not_org')}
            </MenuItem>
        ];
        for (const orgId of Object.keys(_orgsMap)) {
            orgsItems.push(
                <MenuItem key={orgId} value={orgId}>
                    {_orgsMap[orgId].name}
                </MenuItem>
            );
        }
        return orgsItems;
    }
    return (
        <FormControl
            variant="outlined"
            size="small"
            margin="dense"
            className="select"
        >
            <InputLabel id="orgs">{t('fileManager.org')}</InputLabel>
            <Select
                label={t('fileManager.org')}
                labelId="orgs"
                name="org"
                defaultValue={t('fileManager.not_org')}
                value={typeof _orgId === 'undefined' ? 'not_org' : _orgId}
                onChange={(event) => {
                    dispatch(fileManagerOrgChanged(event.target.value));
                }}
            >
                {renderOrgsItems()}
            </Select>
        </FormControl>
    );
}
export default OrgsFilter;
