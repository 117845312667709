import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { meetGetStatus } from './actions';
import { backdropToggled } from '../app/actions';
import meetStatuses from './meetStatuses';
import MeetPage from './MeetPage';
import Iframe from './Iframe';
import Info from './Info';
import Notification from '../main/Notification';

function Meet(props) {
    const {
        match: {
            params: { meetId }
        },
        webinar,
        _meet,
        _locationHost,
        dispatch
    } = props;
    useEffect(() => {
        dispatch(backdropToggled(true));
        dispatch(meetGetStatus(meetId, webinar));
    }, []);
    if (_meet.status === meetStatuses.LOADING) {
        return null;
    }

    return (
        <>
            {_meet.status === meetStatuses.STARTED ? (
                <Iframe
                    info={_meet.info}
                    webinar={webinar}
                    displayName={_meet.webinarRegForm.fio}
                    locationHost={_locationHost}
                    meetId={meetId}
                />
            ) : (
                <MeetPage>
                    <Info meetId={meetId} webinar={webinar} />
                    <Notification />
                </MeetPage>
            )}
            <audio
                id="autoPlayHack"
                src="/silence.mp3"
                controls={false}
                autoPlay={false}
                muted={false}
                style={{ display: 'none' }}
            ></audio>
        </>
    );
}

function mapStateToProps(state) {
    return {
        _meet: state.meet,
        _locationHost: state.app.locationHost
    };
}

export default connect(mapStateToProps)(Meet);
