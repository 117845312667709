import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { meetStart, meetShowWebinarReg, meetWebinarReg } from './actions';
import { isMobile } from './functions';
import { isIOS } from 'react-device-detect';
import { Box, Typography } from '@mui/material';
import meetStatuses from './meetStatuses';
import appStoreLogo from '../css/images/appstore.png'
import googlePlayLogo from '../css/images/googleplay.png'




function Links({ meetId, webinar, isPersonalWebinarLink }) {
    const { _meet, _settings, _joinHidden } = useSelector(state => {
        return {
            _meet: state.meet,
            _settings: state.app.settings,
            _joinHidden: state.meet.info.joinHidden,
        }
    })
    const { t } = useTranslation();
    const dispatch = useDispatch();
    function renderLinks() {

        if (isMobile() && !webinar) {
            return (
                <>
                    {`${t('meet.mobile.start1')} `}

                    <a
                        href="#"
                        onClick={
                            event => {
                                event.preventDefault();
                                dispatch(meetStart(meetId, true));
                            }
                        }
                    >
                        {t('meet.mobile.start2')}
                    </a>

                    <br />
                    <br />

                    {`${t('meet.mobile.appNotInstalled')} `}

                    <div className="download-links">
                        {`${t('meet.mobile.loadFrom')} `}
                        &nbsp;
                        {
                            isIOS
                                ?
                                <a href={_settings.iosApp} className="download-links__link" target="_blank">
                                    <img src={appStoreLogo} width="102" height="30" />
                                </a>
                                :
                                <a href={_settings.androidApp} className="download-links__link" target="_blank">
                                    <img src={googlePlayLogo} width="102" height="30" />
                                </a>
                        }
                    </div>

                    <br />
                    <br />

                    {`${t('meet.mobile.message1')}? `}

                    <a
                        href="#"
                        onClick={
                            event => {
                                event.preventDefault();
                                dispatch(meetStart(meetId));
                            }
                        }
                    >
                        {t('meet.mobile.message2')}
                    </a>
                </>
            )
        }

        return (
            <>
                {`${t('meet.showLink.message1')} `}
                <a
                    href="#"
                    onClick={
                        event => {
                            event.preventDefault();
                            if (webinar) {
                                if (isPersonalWebinarLink) {
                                    dispatch(meetWebinarReg(true, true));
                                } else {
                                    dispatch(meetShowWebinarReg());
                                }
                            } else {
                                dispatch(meetStart(meetId)); // Because it's PC
                            }
                        }
                    }
                >
                    {t('meet.showLink.message2')}
                </a>
                {_joinHidden ?
                    <Box sx={{ mt: 4 }}>
                        <a
                            href="#"

                            onClick={
                                event => {
                                    event.preventDefault();
                                    if (webinar) {
                                        if (isPersonalWebinarLink) {
                                            dispatch(meetWebinarReg(true, true));
                                        } else {
                                            dispatch(meetShowWebinarReg());
                                        }
                                    } else {
                                        dispatch(meetStart(meetId)); // Because it's PC
                                    }
                                }
                            }
                        >
                            {t('meet.showLink.hidden')}
                        </a>
                    </Box>
                    : null}
            </>
        )
    }


    const { status, info } = _meet;

    switch (status) {
        case meetStatuses.SHOWLINK:
            return (

                <Typography>
                    {info.jwt ? `${t('meet.moderatorMessage')}. ` : null}
                    {renderLinks()}
                </Typography>

            )

        case meetStatuses.NOTSTARTED:
            return (
                <Typography>
                    <b>{`${t('meet.notStarted.message1')}...`}</b>
                    <br />
                    {`${t('meet.notStarted.message2')}.`}
                    <br />
                    <br />
                    {`${t('meet.notStarted.message3')}.`}
                </Typography>
            )

        default:
            return (
                <Typography>
                    {`${t('meet.tooEarly.message1')} `}
                    {`${_settings.showLinkTimeout} `}
                    {`${t('meet.tooEarly.message2')}.`}
                </Typography>
            )
    }
}

export default Links;
