export const FILE_MANAGER_LOAD = 'FILE_MANAGER_LOAD';
export const FILE_MANAGER_LOADED = 'FILE_MANAGER_LOADED';
export const FILE_MANAGER_FORM_FIELD_CHANGED =
    'FILE_MANAGER_FORM_FIELD_CHANGED';
export const FILE_MANAGER_TYPE_CHANGED = 'FILE_MANAGER_TYPE_CHANGED';
export const FILE_MANAGER_FILTER = 'FILE_MANAGER_FILTER';
export const FILE_MANAGER_FILTER_DATE_RANGE_CHANGED =
    'FILE_MANAGER_FILTER_DATE_RANGE_CHANGED';
export const FILE_MANAGER_ORG_CHANGED = 'FILE_MANAGER_ORG_CHANGED';
export const FILE_MANAGER_SORT_CHANGED = 'FILE_MANAGER_SORT_CHANGED';
export const FILE_MANAGER_ROWS_ON_PAGE_CHANGED =
    'FILE_MANAGER_ROWS_ON_PAGE_CHANGED';
export const FILE_MANAGER_PAGE_CHANGED = 'FILE_MANAGER_PAGE_CHANGED';
export const FILE_MANAGER_DELETE_FILE = 'FILE_MANAGER_DELETE_FILE';
export const FILE_MANAGER_DOWNLOAD_FILE = 'FILE_MANAGER_DOWNLOAD_FILE';
export const FILE_MANAGER_SELECT_FILES_IDS = 'FILE_MANAGER_SELECT_FILES_IDS';
export const FILE_MANAGER_SELECT_ALL_FILES_CHECKED =
    'FILE_MANAGER_SELECT_ALL_FILES_CHECKED';
export const FILE_MANAGER_DELETED_CHECK = 'FILE_MANAGER_DELETED_CHECK';
export const FILE_MANAGER_CLEAR = 'FILE_MANAGER_CLEAR';
export const FILE_MANAGER_FILTER_RESET = 'FILE_MANAGER_FILTER_RESET';
