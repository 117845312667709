import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalPreview } from './actions';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ModalPreview() {
    const { t } = useTranslation();
    const { _modal } = useSelector((state) => {
        return {
            _modal: state.main.modalPreview
        };
    });
    const dispatch = useDispatch();

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={_modal.isOpen}
            onClose={() => { }}
            aria-labelledby="modal-dialog-title"
            aria-describedby="modal-dialog-description"
        >
            <DialogContent sx={{ p: 0 }}>{_modal.form}</DialogContent>
            <Button
                sx={{
                    position: 'absolute',
                    right: '32px',
                    top: '32px',
                    background: '#0000004f',
                    '&:hover': { background: '#1565c073' },
                    borderRadius: '50%',
                    height: '4rem',
                    width: '4rem'
                }}
                onClick={() => {
                    dispatch(closeModalPreview());
                }}
            >
                <CloseIcon sx={{ fill: '#fff' }} fontSize="large" />
            </Button>
        </Dialog>
    );
}

export default ModalPreview;
