import {
    FILE_MANAGER_FILTER,
    FILE_MANAGER_FILTER_DATE_RANGE_CHANGED,
    FILE_MANAGER_FORM_FIELD_CHANGED,
    FILE_MANAGER_LOAD,
    FILE_MANAGER_LOADED,
    FILE_MANAGER_TYPE_CHANGED,
    FILE_MANAGER_SORT_CHANGED,
    FILE_MANAGER_ORG_CHANGED,
    FILE_MANAGER_ROWS_ON_PAGE_CHANGED,
    FILE_MANAGER_PAGE_CHANGED,
    FILE_MANAGER_DELETE_FILE,
    FILE_MANAGER_DOWNLOAD_FILE,
    FILE_MANAGER_SELECT_FILES_IDS,
    FILE_MANAGER_SELECT_ALL_FILES_CHECKED,
    FILE_MANAGER_CLEAR,
    FILE_MANAGER_FILTER_RESET
} from './actionTypes';

export function fileManagerFilterDateRangeChanged(dates) {
    return {
        type: FILE_MANAGER_FILTER_DATE_RANGE_CHANGED,
        dates
    };
}
export function fileManagerLoad(payload) {
    return {
        type: FILE_MANAGER_LOAD,
        payload
    };
}
export function fileManagerLoaded(payload) {
    return {
        type: FILE_MANAGER_LOADED,
        payload
    };
}
export function fileManagerFormfieldChanged(payload) {
    return {
        type: FILE_MANAGER_FORM_FIELD_CHANGED,
        payload
    };
}

export function fileManagerOrgChanged(payload) {
    return {
        type: FILE_MANAGER_ORG_CHANGED,
        payload
    };
}
export function fileManagerSortChanged(payload) {
    return {
        type: FILE_MANAGER_SORT_CHANGED,
        payload
    };
}
export function fileManagerTypeChanged(payload) {
    return {
        type: FILE_MANAGER_TYPE_CHANGED,
        payload
    };
}
export function fileManagerFilter() {
    return {
        type: FILE_MANAGER_FILTER
    };
}
export function fileManagerRowsOnPageChanged(payload) {
    return {
        type: FILE_MANAGER_ROWS_ON_PAGE_CHANGED,
        payload
    };
}

export function fileManagerPageChanged(payload) {
    return {
        type: FILE_MANAGER_PAGE_CHANGED,
        payload
    };
}

export function fileManagerDeleteFile(fileIds) {
    return {
        type: FILE_MANAGER_DELETE_FILE,
        payload: {
            fileIds
        }
    };
}
export function fileManagerDownloadFile(
    fileType,
    fileId,
    copyToClipboard = false
) {
    return {
        type: FILE_MANAGER_DOWNLOAD_FILE,
        payload: {
            fileType,
            fileId,
            copyToClipboard
        }
    };
}
export function fileManagerSelectFilesIds(payload) {
    return {
        type: FILE_MANAGER_SELECT_FILES_IDS,
        payload
    };
}
export function fileManagerSelectAllFilesChecked(payload) {
    return {
        type: FILE_MANAGER_SELECT_ALL_FILES_CHECKED,
        payload
    };
}
export function fileManagerClear() {
    return {
        type: FILE_MANAGER_CLEAR
    };
}
export function fileManagerFilterReset() {
    return {
        type: FILE_MANAGER_FILTER_RESET
    };
}
