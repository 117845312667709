import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { GetApp, Link, Delete } from '@mui/icons-material';
import Action from '../../Action';
import { showConfirmDialog } from '../../actions';
import { fileManagerDeleteFile, fileManagerDownloadFile } from './actions';
import { useTranslation } from 'react-i18next';

function Buttons({ fileType, fileName, fileId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (
        <>
            <Action
                title={'fileManager.download'}
                icon={<GetApp />}
                onClickHandle={() => {
                    dispatch(fileManagerDownloadFile(fileType, fileId));
                }}
            />

            <Action
                title={'fileManager.copyLink'}
                icon={<Link />}
                onClickHandle={() => {
                    dispatch(fileManagerDownloadFile(fileType, fileId, true));
                }}
            />

            <Action
                title={'fileManager.delete'}
                icon={<Delete />}
                onClickHandle={() => {
                    dispatch(
                        showConfirmDialog({
                            title: 'fileManager.deleteTitle',
                            text: {
                                key: t('fileManager.filter.deleteText', {
                                    name: fileName
                                })
                            },
                            actionButton: 'fileManager.delete',
                            action: fileManagerDeleteFile([fileId])
                        })
                    );
                }}
            />
        </>
    );
}

export default connect()(Buttons);
